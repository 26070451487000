import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CopyAllRounded, WindowSharp } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
// @ts-ignore
import React, { useEffect, useState } from "react";
import axios from "axios";

const Home: React.FC = () => {
  const [text, setText] = useState(
    "ការថតចម្លងឯកសារទុកគឺជាការថតចម្លងព័ត៌មានឌីជីថលសំខាន់ៗរបស់អ្នក (ឧទាហរណ៍ រូបថត ព័ត៌មានហិរញ្ញវត្ថុ ឬកំណត់ត្រា) ដែលអ្នកបានរក្សាទុកទៅក្នុងឧបករណ៍ផ្ទុកទិន្នន័យខាងក្រៅ ឬនៅលើពពក។"
  );
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleChange = (event: { target: { value: string } }) => {
    setResult(event.target.value);
  };
  const url = "https://wordsegment-api.idri.edu.kh/api/v1/word_segment";

  const processResult = async () => {
    setLoading(true);
    try {
      const res: any = await axios.post(url, {
        text: text,
      });
      setResult(res.data.result);
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  function onCopyText(text) {
    window.navigator.clipboard.writeText(text);
    if (text) {
      toast.success("Text is copied");
    }
  }

  function onCopySegment(text) {
    window.navigator.clipboard.writeText(text);
    if (text) {
      toast.success("Segment is copied");
    }
  }

  function onDeleteText() {
    if (text) {
      setText("");
      toast.success("Delete");
    }
  }

  function onDeleteSegment() {
    setResult(null);
    if (text) {
      toast.success("Delete");
    }
  }

  return (
    <Container maxWidth={"xl"} sx={{ pd: 4 }}>
      <Stack sx={{ py: { md: 14, xs: 2 } }}>
        <Stack sx={{ pb: { md: 10, xs: 5 } }}>
          <Typography
            align="center"
            color={"primary"}
            fontSize={{ xs: "16px", sm: "25px", md: "30px" }}
            fontWeight="bold"
            sx={{
              mt: { xs: 0, md: -2, xl: 0 },
              mb: { xs: 0, md: -2, xl: 0 },
              fontFamily: "CADTMonoDisplay",
            }}
          >
            KHMER WORD SEGMENT
          </Typography>
        </Stack>

        <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={5}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Input
              </Typography>
              <Box
                sx={{
                  pb: 1,
                }}
              />
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Stack sx={{ mb: 38 }}>
                        <Tooltip title="Delete">
                          <IconButton onClick={onDeleteText}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </InputAdornment>
                  ),
                }}
                value={text}
                onChange={(e) => setText(e.target.value)}
                multiline
                rows={15}
                variant="outlined"
                fullWidth
              />
              {/* typography on flex-end */}
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{
                  paddingTop: 1,
                  paddingRight: 1,
                }}
              >
                <Typography variant="subtitle2" sx={{ color: "#000" }}>
                  {text.length || 0}/1000 characters
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ height: "100%", py: 1 }}
              >
                <Box />
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    fontWeight: "600",
                    width: "100%",
                    borderRadius: 2,
                    backgroundColor: "#00337E",
                    "&:hover": { backgroundColor: "#0343A3" },
                  }}
                  onClick={processResult}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Process"}
                </Button>
              </Stack>
            </Grid>

            <Grid item xs={12} md={5}>
              {/* <Autocomplete
                sx={{ mt: 2 }}
                freeSolo
                multiple
                options={[]}
                // defaultValue={[top100Films[12].title, top100Films[13].title]}
                value={result || []}
                readOnly
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    rows={15}
                    variant="outlined"
                    fullWidth
                  />
                )}
              /> */}

              {/* <Select
                readOnly
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                rows={15}
                value={result || []}
                onChange={handleChange}
                renderValue={(params) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {params.map((value) => (
                      <TextField disabled key={value} label={value} />
                    ))}
                  </Box>
                )}
              /> */}
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Output
              </Typography>
              <Box
                sx={{
                  pb: 1,
                }}
              />
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Stack sx={{ mb: 35 }}>
                        <Tooltip title="Copy">
                          <IconButton
                            aria-label="copy"
                            onClick={() => onCopySegment(result)}
                          >
                            <CopyAllRounded />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={onDeleteSegment}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </InputAdornment>
                  ),
                }}
                color="primary"
                value={result || []}
                multiline
                rows={15}
                variant="outlined"
                fullWidth
              />
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{
                  paddingTop: 1,
                  paddingRight: 1,
                }}
              >
                <Typography variant="subtitle2" sx={{ color: "#000" }}>
                  {result?.length || 0}/1000 characters
                </Typography>
              </Stack>

              {/* <TextField
                disabled
                multiline
                rows={15}
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Stack sx={{ mb: 33 }} spacing={1}>
                        <CopyToClipboard text={text} onCopy={onCopySegment}>
                          <Tooltip title="Copy">
                            <IconButton>
                              <CopyAllRounded />
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>
                        <Tooltip title="Delete">
                          <IconButton onClick={onDeleteSegment}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </Container>
  );
};

export default Home;
