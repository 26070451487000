import "styles/global.css";
import { Provider } from "react-redux";
import store from "redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "pages";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Layout from "layouts/main";

const theme = createTheme({
  palette: {
    primary: {
      main: "#142544",
      light: "#9fcaf5",
    },
  },
});

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout>
          <Home />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={true}
            pauseOnHover={false}
          />
        </Layout>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
